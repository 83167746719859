.get-deal-page-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    background-color: #dddddd;
    justify-content: start;
    align-items: center;
}

.unerline-login-hover {
position: relative;
display: inline-block;
}

.unerline-login-hover::before {
content: "";
position: absolute;
left: 0;
bottom: 0;
height: 2px;
width: 0;
background-color: tomato;
transition: width 0.3s ease;
}

.unerline-login-hover.hovered::before {
width: 100%;
}
